import httpServices from "../httpServices";

/**
 * 
 * @param {object} data object of name ,last_name , mobile, gender & etc.
 * @returns otp code sent
 */
export const register = (data) => {
  return httpServices.post("otp/register", data);
};

/**
 * 
 * @param {object} data object of mobile 
 * @returns user log in
 */
export const login = (data) => {
  return httpServices.post("otp/login", data);
};

/**
 * 
 * @param {object} data  mini code
 * @returns mobile number verifed
 */
export const verifyCode = data =>{
    return httpServices.post('otp/verify_code',data)
}
