import { createContext, useEffect, useState } from "react";

export const LanguageContext = createContext({
  language: 1,
  setLanguage: () => {},
  handleChangeLanguage: () => {},
});

const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("lang") ? parseInt(localStorage.getItem("lang")) : 1
  );

  /**
   *
   * @param {number} lang 1 => ir , 2 => ar , 3 => en
   */
  const handleChangeLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    setLanguage(lang);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const storedLang = localStorage.getItem("lang");
      if (storedLang) {
        setLanguage(parseInt(storedLang));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, handleChangeLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
