import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getServicesVideos,
  homeSliders,
  setContactUs,
} from "../../services/homeRequests/homeRequests";
import toast from "react-hot-toast";
import { getFAQs } from "../../services/faqsRequests/faqsRequests";

const initialState = {
  loading: false,
  vidoes: [],
  sliders: [],
  faqs: [],
};

export const getAllSliders = createAsyncThunk(
  "getAllSliders/home",
  async () => {
    try {
      const { data } = await homeSliders();

      return data.sliders;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllVideos = createAsyncThunk("getAllVideos/home", async () => {
  try {
    const { data } = await getServicesVideos();
    return data.videos;
  } catch (ex) {
    console.log(ex);
  }
});

export const sendContactUs = createAsyncThunk(
  "sendContactUs/home",
  async (initialData) => {
    try {
      const { status } = await setContactUs(initialData);
      if (status >= 200) {
        toast.success("پیام شما با موفقیت ارسال شد");
        setTimeout(() => {
          window.location.reload("/");
        }, 1500);
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllFAQs = createAsyncThunk("getAllFAQs/home", async () => {
  try {
    const { data } = await getFAQs();
    return data.faqs;
  } catch (ex) {
    console.log(ex);
  }
});

export const homeSlice = createSlice({
  name: "home",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllSliders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSliders.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sliders = payload;
      })
      .addCase(getAllVideos.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllVideos.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.vidoes = payload;
      })
      .addCase(sendContactUs.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendContactUs.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getAllFAQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFAQs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.faqs = payload;
      });
  },
});

export default homeSlice.reducer;
