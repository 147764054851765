import httpServices from "../httpServices";

/**
 *
 * @returns array of services list
 */
export const getServices = () => {
  return httpServices.get("index/services");
};

/**
 *
 * @param {number} service_id id of service which want to fetch details
 * @returns service details
 */
export const getSerivce = (service_id) => {
  return httpServices.get(`service/single/${service_id}`);
};

/**
 *
 * @returns array of slider services
 */
export const getServicesSlider = () => {
  return httpServices.get("index/service_slider");
};

/**
 *
 * @returns array of before after services
 */
export const getBeforAfterServices = () => {
  return httpServices.get("index/service_before_after");
};
