import { configureStore } from "@reduxjs/toolkit";
import homeSlice from "../features/homeSlice";
import servicesSlice from "../features/servicesSlice";
import blogSlice from "../features/blogSlice";
import authSlice from "../features/authSlice";

export const store = configureStore({
  reducer: {
    home: homeSlice,
    services: servicesSlice,
    blogs: blogSlice,
    auth: authSlice,
  },
});
