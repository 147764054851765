import axios from "axios";
import httpServices from "../httpServices";
import { config } from "../../config/config";

/**
 * @param {number} page number of page
 * @returns array of blogs list
 */
export const getBlogs = (page) => {
  return httpServices.get(`/blog?page=${page}`);
};

/**
 *
 * @param {number} blog_id id of blog which want to fetch details
 * @returns blog details
 */
export const getBlog = (blog_id) => {
  return httpServices.get(`blog/single/${blog_id}`);
};

export const setComment = (data) => {
  return axios.post(`${config.base_url}/api/store_comment`, data);
};
