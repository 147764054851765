import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getBeforAfterServices,
  getSerivce,
  getServices,
} from "../../services/serviceRequests/servicesRequests";

const initialState = {
  loading: false,
  services: [],
  service_details: {},
  services_slider: [],
  comparative: [],
};

export const getAllServices = createAsyncThunk(
  "getAllServices/services",
  async () => {
    try {
      const { data } = await getServices();
      return data.services;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllServicesSliders = createAsyncThunk(
  "getAllServicesSliders/services",
  async () => {
    try {
      const { data } = await getServices();
      return data.services;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getServiceById = createAsyncThunk(
  "getServiceById/services",
  async (service_id) => {
    try {
      const { data } = await getSerivce(service_id);
      return data.service;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getComparativeServices = createAsyncThunk(
  "getComparativeServices/services",
  async () => {
    try {
      const { data } = await getBeforAfterServices();
      return data.service_before_after;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllServices.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.services = payload;
      })
      .addCase(getAllServicesSliders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllServicesSliders.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.services_slider = payload;
      })
      .addCase(getComparativeServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getComparativeServices.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.comparative = payload;
      })
      .addCase(getServiceById.pending, (state) => {
        state.loading = true;
      }).addCase(getServiceById.fulfilled,(state,{payload}) =>{
        state.service_details = payload;
        state.loading = false;
      })
  },
});

export default servicesSlice.reducer;
