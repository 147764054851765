/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

const token = Cookies.get("olga_token");

axios.defaults.baseURL = "https://api.olgaclinic.com/api/v1/";

if (token) {
  axios.defaults.headers.post.Authorization = `Bearer ${token}`;
}

axios.interceptors.response.use(null, (err) => {
  const expectedError = err.response && err.response.status >= 500;
  if (err.response && err.response.status === 419) {
    toast.error("CSRF Token mismatch");
  }
  if (expectedError) {
    toast.error("خطایی از سمت سرور رخ داده است");
  }

  if (err.response && err.response.data) {
    const errorMessage = err.response.data;

    if (errorMessage?.errors) {
      const errors = Object.values(errorMessage?.errors);
      errors.map((error) => toast.error(error));
    } else {
      toast.error(errorMessage?.message);
    }
  }

  return Promise.reject(err);
});

export default {
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  patch: axios.patch,
};
