import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getBlog,
  getBlogs,
  setComment,
} from "../../services/blogsRequests/blogsRequests";
import toast from "react-hot-toast";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const initialState = {
  loading: false,
  blogs: [],
  blog_details: {},
};

const Alert = withReactContent(Swal);

export const getAllBlogs = createAsyncThunk(
  "getAllBlogs/blogs",
  async (page) => {
    try {
      const { data } = await getBlogs(page);
      return data.blogs;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getBlogById = createAsyncThunk(
  "getBlogById/blogs",
  async (blog_id) => {
    try {
      const { data } = await getBlog(blog_id);
      return data.blog;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewComment = createAsyncThunk(
  "addNewComment/blogs",
  async (initialData) => {
    try {
      const { status } = await setComment(initialData);
      if (status >= 200) {
        Alert.fire({
          title: "کامنت ثبت شد",

          text: "کامنت شما با موفقیت ثبت شد و پس از تایید نمایش داده خواهد شد",
          confirmButtonText: "باشه",
          icon: "success",
        });

      }
    } catch (ex) {
      console.log(ex);
      if (ex.response?.status >= 401) {
        toast("لطفا اول وارد شوید");
        window.location.replace("/login");
      }
    }
  }
);

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, { payload }) => {
        state.blogs = payload;
        state.loading = false;
      })
      .addCase(getBlogById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogById.fulfilled, (state, { payload }) => {
        state.blog_details = payload;
        state.loading = false;
      })
      .addCase(addNewComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewComment.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default blogSlice.reducer;
