import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/js/bootstrap.bundle";
//styles
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css/pagination";
import "react-before-after-slider-component/dist/build.css";
import LanguageContextProvider from "./context/LanguageContext";

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename="/">
    <Provider store={store}>
      <LanguageContextProvider>
        <App />
      </LanguageContextProvider>
    </Provider>
    <ToastContainer
      position="top-right"
      theme="colored"
      pauseOnHover={false}
      closeOnClick={true}
    />
    <Toaster position="top-left" />
  </BrowserRouter>
);
