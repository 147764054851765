import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  login,
  register,
  verifyCode,
} from "../../services/authRequests/authRequests";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

const initialState = {
  loading: false,
};

export const registerUser = createAsyncThunk(
  "registerUser/auth",
  async (initialData) => {
    try {
      const { status } = await register(initialData);
      if (status >= 200) {
        toast.success("کد تایید ارسال شده");
        window.location.replace("/verify");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const loginUser = createAsyncThunk(
  "loginUser/auth",
  async (initialData) => {
    try {
      const { status } = await login(initialData);
      if (status >= 200) {
        toast.success("کد تایید ارسال شد");
        window.location.replace("/verify");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const OTPVerifyCode = createAsyncThunk(
  "OTPVerifyCode/auth",
  async (initialData) => {
    try {
      const { status, data } = await verifyCode(initialData);
      if (status >= 200) {
        Cookies.set("olga_token", data?.token);
        Cookies.set("user",`${data?.user?.name} ${data?.user?.last_name}`)
        toast.success("تایید شد");
        window.location.replace("/");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(OTPVerifyCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(OTPVerifyCode.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default authSlice.reducer;
