import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "./utils/Loading";

const MainLayouts = lazy(() => import("./layouts/MainLayouts"));
const HomePage = lazy(() => import("./pages/Home/HomePage"));
const Login = lazy(() => import("./pages/Login/Login"));
const OTPVerify = lazy(() => import("./pages/Verify/OTPVerify"));
const Register = lazy(() => import("./pages/Register/Register"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const AboutPage = lazy(() => import("./pages/About/AboutPage"));
const BlogsPage = lazy(() => import("./pages/Blogs/BlogsPage"));
const BlogDetails = lazy(() => import("./components/BlogDetails/BlogDetails"));
const FAQs = lazy(() => import("./components/FAQs/FAQs"));
const ClinicServicesPage = lazy(() =>
  import("./pages/Services/ClinicServicesPage")
);
const ServiceDetailsPage =lazy(() => import("./pages/Services/ServiceDetailsPage"))

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <MainLayouts>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify" element={<OTPVerify />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogs/:id" element={<BlogDetails />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/services" element={<ClinicServicesPage />} />
          <Route path="/services/:id" element={<ServiceDetailsPage />} />
        </Routes>
      </MainLayouts>
    </Suspense>
  );
};

export default App;
