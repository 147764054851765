import httpServices from "../httpServices";

/**
 *
 * @returns array of home sliders
 */
export const homeSliders = () => {
  return httpServices.get("index/slider");
};

/**
 *
 * @returns array of all services vidoes
 */
export const getServicesVideos = () => {
  return httpServices.get("index/videos");
};

/**
 *
 * @param {object} data object of name , mobile,description
 * @returns contact us message send
 */
export const setContactUs = (data) => {
  return httpServices.post("index/contact_us",data);
};
